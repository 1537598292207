<template>
  <el-dialog
    top="30px"
    append-to-body
    :visible.sync="currentShowFlag"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <div
      slot="title"
      class="detail-dialog__header"
    >
      {{ $t('dealer_reviewConfirm.appeal') }}
    </div>
    <div>
      <simple-form
        ref="form"
        v-model="formModel"
        label-width="80px"
        :form-field="formField"
        :grid="{xs: 24, sm: 12, md: 8}"
        :view-flag="viewFlag"
      />
    </div>
    <ag-grid
      ref="appealGrid"
      style="width:100%;height:360px;"
      edit-type="fullRow"
      :column-defs="columnDefs"
      :row-data.sync="tableData"
      :framework-components="frameworkComponents"
      :grid-options="gridOptions"
    >
      <simple-form
        v-model="searchModel"
        :form-field="searchFormField"
      >
        <template slot="tableOperation">
          <el-button
            v-if="!viewFlag"
            type="primary"
            @click="handleAddClick"
          >
            {{ $t('operation.create') }}
          </el-button>
        </template>
      </simple-form>
    </ag-grid>
    <div slot="footer">
      <el-button
        @click="handleDialogClosed"
      >
        {{ viewFlag ? $t('operation.close') : $t('operation.cancel') }}
      </el-button>
      <el-button
        v-if="!viewFlag"
        type="primary"
        @click="handleDataSubmit"
      >
        {{ $t('operation.confirm') }}
      </el-button>
    </div>
    <simple-upload
      ref="uploader"
      :file-list="fileList"
      url="/system/attachment/upload"
      :show-flag.sync="isShowUploader"
      @confirm="handleUploadConfirm"
      @on-success="handleUploadSuccess"
    />
  </el-dialog>
</template>

<script>
import Vue from 'vue'

const BASEURL = {
  get: '/jlr/tt/selfExaminationData/get',
  download: '/system/attachment/download',
  update: '/jlr/tt/selfExaminationData/update'
}

/** 自定义操作列 */
const Operation = Vue.extend({
  inject: ['handleDeleteClick', 'handleImportClick'],
  template: `<div style="height:100%;text-align:center;">
      <el-tooltip effect="light" :content="$t('operation.delete')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-delete" @click="handleDeleteClick(params)" />
      </el-tooltip>
    </div>`
})

/** 自定义单元格按钮 附件上传 */
const AddDocsButton = Vue.extend({
  inject: ['handleImportClick'],
  template: `<div style="height:100%;text-align:center;">
      <el-tooltip effect="light" :content="$t('dealer_anomaly.addDocs')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-upload" @click="handleImportClick(params)" />
      </el-tooltip>
    </div>`
})

/** 自定义单元格按钮 附件下载 */
const Attachment = Vue.extend({
  inject: ['handleImportClick', 'downloadFile', 'deleteFile'],
  template: `<div style="height:100%;">
      <el-tooltip effect="light" :content="$t('dealer_reviewConfirm.attachment')">
        <el-button type="text" icon="el-icon-document" @click="downloadFile(params.data)"
          v-if="params.data.attachment && params.data.attachment.id">
          {{ params.data.attachment ? (params.data.attachment.fileName ? (params.data.attachment.fileName + '.' + params.data.attachment.fileType) : '') : '' }}
        </el-button>
      </el-tooltip>
      <el-button type="text" icon="el-icon-circle-close" @click="deleteFile(params)" 
        v-if="!params.viewFlag && (params.data.attachment && params.data.attachment.id)" />
    </div>`
})

export default {
  name: 'Appeal',
  props: {
    showFlag: { type: Boolean, default: false },
    viewFlag: { type: Boolean },
    detailData: { type: Object }
  },
  inject: ['handleDataSearch'],
  data () {
    return {
      searchModel: {},
      tableData: [{}],
      formModel: {},
      fileList: [],
      currentRowNode: {},
      gridOptions: {},
      isShowUploader: false,
      frameworkComponents: {}
    }
  },
  provide () {
    return {
      handleDeleteClick: this.handleDeleteClick,
      handleImportClick: this.handleImportClick,
      downloadFile: this.downloadFile,
      deleteFile: this.deleteFile
    }
  },
  computed: {
    currentShowFlag: {
      get () {
        return this.showFlag
      },
      set (val) {
        this.$emit('update:showFlag', val)
      }
    },
    searchFormField () {
      return [
        { slotName: 'tableOperation', col: { xs: 24, sm: 24, md: 24 }, style: { textAlign: 'right' }, labelWidth: '0' }
      ]
    },
    formField () {
      return [
        {
          prop: 'reviewConfirmUser',
          type: 'Input',
          label: this.$t('dealer_reviewConfirm.appealBy'),
          component: { clearable: true },
          col: { xs: 12, sm: 12, md: 12 },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        }
      ]
    },
    columnDefs () {
      return [
        {
          headerName: this.$t('dealer_reviewConfirm.appealContent'),
          field: 'appealContent',
          editable: true,
          cellEditorParams: {
            rules: { required: true, message: this.$t('validate.isRequired') }
          },
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_reviewConfirm.uploadFiles'),
          suppressSizeToFit: true,
          hide: this.viewFlag,
          cellRenderer: 'AddDocsButton',
          cellRendererParams: { viewFlag: this.viewFlag },
          width: 100
        },
        {
          headerName: this.$t('dealer_reviewConfirm.attachment'),
          cellRenderer: 'Attachment',
          cellRendererParams: { viewFlag: this.viewFlag },
          minWidth: 200
        },
        {
          headerName: this.$t('thirdParty_appealContent.thirdPartyReply'),
          field: 'reply',
          minWidth: 380
        },
        {
          headerName: this.$t('thirdParty_appealContent.finalConfirm'),
          field: 'finalResult',
          minWidth: 140,
          valueFormatter: params => this.$getDictLabel({ type: 'tt_final_result', value: params.value })
        },
        {
          headerName: this.$t('field.operation'),
          width: 68,
          hide: this.viewFlag,
          pinned: 'right',
          suppressSizeToFit: true,
          cellRenderer: 'Operation'
        }
      ]
    }
  },
  beforeMount () {
    this.frameworkComponents = {
      Operation: Operation,
      AddDocsButton: AddDocsButton,
      Attachment: Attachment
    }
  },
  methods: {
    handleDialogClosed () {
      this.tableData = [{ attachment: {} }]
      this.$refs.form.resetFields()
      this.formModel = {}
      this.currentShowFlag = false
    },
    handleDataSubmit () {
      this.gridOptions.api.stopEditing()
      this.$refs.form.$refs.simpleForm.validate(status => {
        if (status) {
          if (!this.tableData.length) {
            return this.$message({ type: 'warning', message: this.$t('dealer_reviewConfirm.appealTip') })
          }
          const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
          const submitModel = this.$_.cloneDeep(this.formModel)
          submitModel.reviewConfirmList = this.$_.cloneDeep(this.tableData)
          submitModel.reviewConfirmStatus = 'tt_review_confirm_status_2'
          submitModel.confirmFlag = true
          this.$axios
            .post(BASEURL.update, submitModel)
            .then(resp => {
              this.currentShowFlag = false
              this.$message({ type: 'success', message: this.$t('tip.saveSuccess') })
              this.handleDataSearch()
            })
            .finally(() => {
              loadingFlag.close()
            })
        }
      })
    },
    handleAddClick () {
      this.tableData.push({ attachment: { id: '' } })
      this.$nextTick(() => {
        this.$refs.appealGrid.focusOnCell(this.tableData.length - 1, 'appealContent')
      })
    },
    downloadFile (rowData) {
      this.$utils.fileSaveAs(BASEURL.download, { id: rowData.attachment.id })
    },
    deleteFile (row) {
      const data = row.data
      data.attachment = { id: '' }
    },
    handleDeleteClick (params) {
      this.tableData.splice(params.node.rowIndex, 1)
    },
    handleDialogOpen () {
      this.$nextTick(() => { this.$refs.appealGrid.redrawGrid(this.columnDefs) })
      const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
      this.$axios
        .post(BASEURL.get, { id: this.detailData.id })
        .then(resp => {
          const respData = resp.data
          this.formModel = respData
          this.tableData = respData.reviewConfirmList
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleImportClick (params) {
      this.currentRowNode = params.node
      this.isShowUploader = true
    },
    handleUploadSuccess (response, file, fileList) {
      let data = this.currentRowNode.data
      data.attachment = file.response.data
      this.isShowUploader = false
    },
    handleUploadConfirm () {}
  }
}
</script>
<style lang="less" scoped>
  .page__wrapper {
    width: 100%;
    height: 100%;
  }
</style>
